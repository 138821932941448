<template>
  <app-page
    ref="page"
    :toolbar="toolbar"
    :list="list"
    :creator="creator"
    :updater="updater"
  >
    <template #actions="{ row }">
      <el-button size="small" type="text" @click="onUpdating(row)">
        编辑
      </el-button>
      <el-button size="small" type="text" @click="onRemoving(row)">
        删除
      </el-button>
    </template>
  </app-page>
</template>

<script>
import request from '@/utils/request';

export default {
  name: 'SettingSetmeal',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        model: {
          name: undefined
        },
        fields: [
          {
            label: '套餐名称',
            prop: 'name',
            placeholder: '请输入要搜索的套餐名称'
          }
        ]
      },
      list: {
        method: 'post',
        url: '/cosmetology/project/setmeal',
        fields: [
          { label: '项目', prop: 'cosmetologyProjectName', width: 300 },
          {
            label: '套餐单价',
            prop: 'unitPrice',
            width: 120,
            align: 'right',
            formatter(row) {
              return `￥${row.unitPrice}`;
            }
          },
          {
            label: '套餐总价',
            prop: 'totalPrice',
            width: 120,
            align: 'right',
            formatter(row) {
              return `￥${row.totalPrice}`;
            }
          },
          { label: '次数', prop: 'number', width: 120, align: 'right' }
        ],
        action: true
      },
      creator: this.getCreator(),
      updater: this.getUpdater()
    };
  },
  methods: {
    getCreator() {
      return {
        width: '400px',
        title: '新增套餐',
        text: '新增套餐',
        size: 'small',
        labelWidth: '120px',
        submit: true,
        url: '/cosmetology/project/setmeal/create',
        model: {
          cosmetologyProjectId: undefined,
          totalPrice: undefined,
          number: undefined
        },
        rules: {
          cosmetologyProjectId: { required: true, message: '名称是必填的' },
          totalPrice: { required: true, message: '套餐总价是必填的' },
          number: { required: true, message: '次数必填的' }
        },
        fields: [
          {
            label: '项目',
            prop: 'cosmetologyProjectId',
            type: 'select',
            placeholder: '请输入项目',
            clearable: true,
            options: [],
            mapper(item) {
              return {
                label: item.name,
                value: item.id
              };
            },
            url: '/cosmetology/project/allList',
            method: 'post'
          },
          {
            label: '套餐总价',
            prop: 'totalPrice',
            type: 'input-number',
            placeholder: '请输入套餐总价'
          },
          {
            label: '次数',
            prop: 'number',
            type: 'input-number',
            placeholder: '请输入次数'
          }
        ]
      };
    },
    getUpdater() {
      const target = this.getCreator();
      return Object.assign(target, {
        text: '更新服务时段',
        url: '/cosmetology/project/setmeal/update'
      });
    },
    onUpdating(row) {
      const { updater, $refs } = this;
      updater.model = row;
      $refs.page.onUpdating();
    },
    async onRemoving(row) {
      const { $confirm, $refs } = this;
      try {
        await $confirm('确认要删除套餐吗?', '提示', {
          type: 'warning'
        });
        await request.post('/cosmetology/project/setmeal/remove', {
          ids: [row.id]
        });
        await $refs.page.getItems();
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

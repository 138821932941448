<template>
  <app-page
    ref="page"
    :toolbar="toolbar"
    :list="list"
    :creator="creator"
    :updater="updater"
  >
    <template #actions="{ row }">
      <el-button size="small" type="text" @click="onUpdating(row)">
        编辑
      </el-button>
      <el-button size="small" type="text" @click="onRemoving(row)">
        删除
      </el-button>
    </template>
  </app-page>
</template>

<script>
import request from '@/utils/request';

export default {
  name: 'SettingTime',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        model: {
          startTime: undefined,
          endTime: undefined
        },
        fields: [
          {
            label: '开始时段',
            prop: 'startTime',
            placeholder: '请输入要搜索的开始时段'
          },
          {
            label: '结束时段',
            prop: 'endTime',
            placeholder: '请输入要搜索的结束时段'
          }
        ]
      },
      list: {
        method: 'post',
        url: '/cosmetology/time',
        fields: [
          { label: '开始时段', prop: 'startTime', width: 120 },
          { label: '结束时段', prop: 'endTime', width: 120 }
        ]
      },
      creator: this.getCreator(),
      updater: this.getUpdater()
    };
  },
  methods: {
    getCreator() {
      return {
        width: '400px',
        title: '新增服务时段',
        text: '新增服务时段',
        size: 'small',
        labelWidth: '80px',
        submit: true,
        url: '/cosmetology/time/create',
        model: {
          startTime: undefined,
          endTime: undefined
        },
        rules: {
          startTime: { required: true, message: '开始时段是必选的' },
          endTime: { required: true, message: '结束时段是必填的' }
        },
        fields: [
          {
            label: '开始时段',
            prop: 'startTime',
            type: 'time',
            placeholder: '请输入开始时段',
            clearable: true,
            valueFormat: 'HH:mm',
            format: 'HH:mm',
            pickerOptions: {
              start: '06:00',
              step: '00:30',
              end: '22:00'
            }
          },
          {
            label: '结束时段',
            prop: 'endTime',
            type: 'time',
            placeholder: '请输入结束时段',
            clearable: true,
            valueFormat: 'HH:mm',
            format: 'HH:mm',
            pickerOptions: {
              start: '06:00',
              step: '00:30',
              end: '22:00'
            }
          }
        ]
      };
    },
    getUpdater() {
      const target = this.getCreator();
      return Object.assign(target, {
        text: '更新服务时段',
        url: '/cosmetology/time/update'
      });
    },
    onUpdating(row) {
      const { updater, $refs } = this;
      updater.model = row;
      $refs.page.onUpdating();
    },
    async onRemoving(row) {
      const { $confirm, $refs } = this;
      try {
        await $confirm('确认要删除项目吗?', '提示', {
          type: 'warning'
        });
        await request.post('/cosmetology/time/remove', {
          ids: [row.id]
        });
        await $refs.page.getItems();
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<template>
  <app-page
    ref="page"
    :toolbar="toolbar"
    :list="list"
    :creator="creator"
    :updater="updater"
  >
    <template #actions="{ row }">
      <el-button type="text" @click="onUpdating(row)">
        编辑
      </el-button>
      <el-button type="text" @click="onRemoving(row)">
        删除
      </el-button>
    </template>
  </app-page>
</template>

<script>
import request from '@/utils/request';

export default {
  name: 'SettingProject',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        model: {
          name: undefined
        },
        fields: [
          {
            label: '项目名称',
            prop: 'name',
            placeholder: '请输入要搜索的项目名称'
          }
        ]
      },
      list: {
        method: 'post',
        url: '/cosmetology/project',
        fields: [
          { label: '项目', prop: 'name', width: 300 },
          {
            label: '单价',
            prop: 'price',
            width: 120,
            aligin: 'right',
            align: 'right',
            formatter(row) {
              return `￥${row.price}`;
            }
          },
          {
            label: '用时(分钟)',
            prop: 'timeCost',
            width: 120,
            aligin: 'right',
            align: 'right'
          }
        ]
      },
      creator: this.getCreator(),
      updater: this.getUpdater()
    };
  },
  methods: {
    getCreator() {
      return {
        width: '400px',
        title: '新增服务项目',
        text: '新增服务项目',
        size: 'small',
        labelWidth: '120px',
        submit: true,
        url: '/cosmetology/project/create',
        model: {
          name: undefined,
          price: undefined,
          timeCost: undefined
        },
        rules: {
          name: { required: true, message: '项目是必填的' },
          price: { required: true, message: '单价是必选的' },
          timeCost: { required: true, message: '用时(分钟)必填的' }
        },
        fields: [
          { label: '项目', prop: 'name', placeholder: '请输入项目' },
          { label: '价格', prop: 'price', placeholder: '请输入价格' },
          {
            label: '用时(分钟)',
            prop: 'timeCost',
            placeholder: '请输入用时(分钟)'
          }
        ]
      };
    },
    getUpdater() {
      const target = this.getCreator();
      return Object.assign(target, {
        text: '更新服务项目',
        url: '/cosmetology/project/update'
      });
    },
    onUpdating(row) {
      const { updater, $refs } = this;
      updater.model = row;
      $refs.page.onUpdating();
    },
    async onRemoving(row) {
      const { $confirm, $refs } = this;
      try {
        await $confirm('确认要删除项目吗?', '提示', {
          type: 'warning'
        });
        await request.post('/cosmetology/project/remove', {
          ids: [row.id]
        });
        await $refs.page.getItems();
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<template>
  <app-tabs v-model="active">
    <el-tab-pane label="时间设置" lazy>
      <setting-time />
    </el-tab-pane>
    <el-tab-pane label="项目设置" lazy>
      <setting-project />
    </el-tab-pane>
    <el-tab-pane label="套餐设置" lazy>
      <setting-setmeal />
    </el-tab-pane>
  </app-tabs>
</template>

<script>
import settingTime from './settingTime';
import settingProject from './settingProject';
import settingSetmeal from './settingSetmeal';

export default {
  name: 'Setting',
  components: { settingTime, settingProject, settingSetmeal },
  data() {
    return {
      active: '0'
    };
  }
};
</script>
